.facts {
  // background: $lightPurpleBg;
  padding-bottom: 100px;

  @media (min-width: $largebp) {
    padding-bottom: 174px;
    padding-right: 80px;
  }

  &__columns {
    @media (min-width: $largebp) {
      display: flex;
      gap: 20px;
    }

    @media (min-width: $xlargebp) {
      display: flex;
      gap: 60px;
    }
  }

  &__column {
    max-width: 320px;
    margin: 0 auto;

    @media (min-width: $largebp) {
      max-width: none;
      margin: 0;
    }

    & + & {
      margin-top: 100px;

      @media (min-width: $largebp) {
        margin: 0;
      }
    }

    &--flex {
      @media (min-width: $largebp) {
        flex: 1;
      }
    }

    &--last {
      @media (min-width: $largebp) {
        width: 250px;
      }
    }
  }

  &__logo {
    margin-bottom: 60px;

    @media (min-width: $largebp) {
      height: 100px;
    }

    svg {
      width: 100%;
      margin: 0 auto;

      @media (min-width: $largebp) {
        margin: 0;
      }
    }

    &--breakthrough svg {
      max-width: 226px;

      @media (min-width: $largebp) {
        max-width: 346px;
      }
    }

    &--tishman svg {
      max-width: 240px;

      @media (min-width: $largebp) {
        max-width: 380px;
      }

      @media (min-width: $xlargebp) {
        max-width: 406px;
      }
    }

    &--bellco svg {
      max-width: 103px;

      @media (min-width: $largebp) {
        max-width: 121px;
      }
    }
  }

  &__facts {
    @media (min-width: $largebp) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
    }

    .facts__fact:nth-of-type(1n + 3) {
      @media (min-width: $largebp) {
        padding-bottom: 0;
      }
    }
  }

  &__fact {
    padding-left: 24px;
    border-left: 1px solid $purple;
    padding-bottom: 24px;
    margin-left: 40px;

    @media (min-width: $largebp) {
      margin-left: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    h3 {
      font-size: 22px;
      margin-bottom: 10px;

      @media (min-width: $largebp) {
        font-size: 26px;
      }

      @media (min-width: $xlargebp) {
        font-size: 40px;
      }
    }

    p {
      font-size: 14px;
      max-width: 220px;

      @media (min-width: $largebp) {
        font-size: 16px;
        max-width: none;
      }

      @media (min-width: $xlargebp) {
        font-size: 20px;
      }
    }
  }
}
