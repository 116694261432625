.specs {
  background: $lightPurpleBg;
  padding-bottom: 100px;

  @media (min-width: $largebp) {
    padding: 130px 0;
    padding-right: 80px;
  }

  .width {
    position: relative;
  }

  &__content {
    @media (min-width: $largebp) {
      width: 50%;
      margin: 0 0 0 auto;
    }
  }

  &__top {
    background: $purple;
    color: $white;
    text-align: center;
    padding: 115px 20px 55px;

    @media (min-width: $largebp) {
      background: transparent;
      color: $purple;
      padding: 0;
      text-align: left;
    }
  }

  &__title {
    font-size: 31px;
    max-width: 350px;
    margin: 0 auto;

    @media (min-width: $largebp) {
      font-size: 42px;
      max-width: none;
      margin: 0 0 40px;
    }

    @media (min-width: $xlargebp) {
      font-size: 62px;
    }
  }

  &__divider {
    background: $white;
    height: 1px;
    width: 100%;
    max-width: 350px;
    margin: 50px auto 30px;

    @media (min-width: $largebp) {
      display: none;
    }
  }

  &__subtitle {
    font-size: 22px;

    @media (min-width: $largebp) {
      font-size: 32px;
    }

    @media (min-width: $xlargebp) {
      font-size: 48px;
    }
  }

  &__fade {
    height: 130px;
    background: linear-gradient(180deg, $purple 15.1%, $lightPurpleBg 86.46%);

    @media (min-width: $largebp) {
      display: none;
    }
  }

  &__logo {
    padding: 0 20px;
    margin-bottom: 60px;
    margin-top: 20px;

    @media (min-width: $largebp) {
      display: none;
      margin-top: 0;
    }

    svg {
      width: 350px;
      margin: 0 auto;
    }
  }

  &__image {
    border-radius: 50%;
    margin: 0 auto;
    max-width: 350px;
    width: 80%;

    @media (min-width: $largebp) {
      max-width: calc(50% - 80px);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    @media (min-width: $xlargebp) {
      max-width: calc(50% - 160px);
    }
  }
}

.specs-items {
  padding: 0 20px;
  max-width: 350px;
  margin: 60px auto 0;

  @media (min-width: $largebp) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    max-width: none;
    padding: 0;
  }

  &__item {
    border-left: 1px solid $purple;
    padding-left: 40px;
    padding-bottom: 40px;

    @media (min-width: $largebp) {
      padding-left: 20px;
    }

    @media (min-width: $xlargebp) {
      padding-left: 40px;
    }

    strong {
      font-size: 31px;
      display: block;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-size: clamp(31px, 6vw, 52px);

      @media (min-width: $largebp) {
        font-size: clamp(31px, 3vw, 52px);
      }
    }

    span {
      text-transform: uppercase;
      font-size: 15px;

      @media (min-width: $largebp) {
        font-size: 18px;
      }

      @media (min-width: $xlargebp) {
        font-size: 22px;
      }
    }
  }

  &__item:last-of-type {
    padding-bottom: 0;
  }

  &__item:nth-of-type(1) {
    @media (min-width: $largebp) {
      order: 1;
      width: 40%;
    }
  }

  &__item:nth-of-type(2) {
    @media (min-width: $largebp) {
      order: 3;
      width: 40%;
      padding-bottom: 0;
    }
  }

  &__item:nth-of-type(3) {
    @media (min-width: $largebp) {
      order: 2;
    }
  }

  &__item:nth-of-type(4) {
    @media (min-width: $largebp) {
      order: 4;
      padding-bottom: 0;
    }
  }
}
