.steps {
  // background: $purple;
  color: $white;
  padding: 60px 0;
  padding-top: 120px;

  @media (min-width: $largebp) {
    padding-right: 80px;
  }

  &__flex {
    @media (min-width: $largebp) {
      display: flex;
      gap: 60px;
      align-items: center;
    }
  }

  &__images {
    margin-bottom: 60px;

    @media (min-width: $largebp) {
      width: 60%;
      margin-bottom: 0;
    }
  }

  &__text {
    @media (min-width: $largebp) {
      flex: 1;
      width: 40%;
      max-width: 450px;
    }
  }

  &__title {
    font-size: 31px;
    max-width: 450px;
    margin: 0 auto 30px;
    text-transform: none;

    @media (min-width: $largebp) {
      border-radius: 50%;
      font-size: 48px;
      margin: 0 0 60px;
    }
  }

  &__p {
    font-size: 19px;
    text-transform: none;

    @media (min-width: $largebp) {
      font-size: 25px;
    }
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .slick-next {
    right: -17px;
    background-image: url("./images/right-arrow-black.svg");
  }

  .slick-prev {
    left: -17px;
    background-image: url("./images/left-arrow-black.svg");
  }
}
