.button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 2px solid transparent;
  text-transform: uppercase;
  line-height: 30px;
  padding: 0 12px;
  transition: 0.2s border-color, 0.2s color, 0.2s background-color, 0.2s opacity;
  font-size: 14px;

  @media (min-width: $largebp) {
    padding: 0 20px;
    line-height: 38px;
    font-size: 16px;
  }

  &--line-purple {
    border-color: $purple;
    color: $purple;

    &:hover {
      color: $white;
      background: $purple;
    }
  }

  &--line-white {
    border-color: $white;
    color: $white;

    &:hover {
      color: $purple;
      background: $white;
    }
  }

  &--white {
    border-color: $white;
    color: $purple;
    background: $white;

    &:hover {
      opacity: 0.8;
    }
  }
}
