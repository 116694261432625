.logos {
  // background: $purple;
  padding: 0 0 60px 0;

  @media (min-width: $largebp) {
    padding: 0 80px 100px 0;
  }

  &__logos {
    text-align: center;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    padding: 45px 0;

    @media (min-width: $largebp) {
      padding: 80px 0;
      column-gap: 50px;
    }
  }

  &__logo {
    display: inline-flex;

    &--blustonelane {
      width: calc(74.45px * 0.5);

      @media (min-width: 600px) {
        width: calc(74.45px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 149px;
      }
    }

    &--dibrunobros {
      width: calc(93.28px * 0.5);

      @media (min-width: 600px) {
        width: calc(93.28px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 194px;
      }
    }

    &--parc {
      width: calc(65px * 0.5);

      @media (min-width: 600px) {
        width: calc(65px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 120px;
      }
    }

    &--traderjoes {
      width: calc(68.17px * 0.5);

      @media (min-width: 600px) {
        width: calc(68.17px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 177px;
      }
    }

    &--sweetgreen {
      width: calc(100px * 0.5);

      @media (min-width: 600px) {
        width: calc(100px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 250px;
      }
    }

    &--fourseasons {
      width: calc(79.43px * 0.5);

      @media (min-width: 600px) {
        width: calc(79.43px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 159px;
      }
    }

    &--vernick {
      width: calc(90px * 0.5);

      @media (min-width: 600px) {
        width: calc(90px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 190px;
      }
    }

    &--solidcore {
      width: calc(90.59px * 0.5);

      @media (min-width: 600px) {
        width: calc(90.59px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 228px;
      }
    }

    &--shakeshack {
      width: calc(109.43px * 0.5);

      @media (min-width: 600px) {
        width: calc(109.43px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 234px;
      }
    }

    &--lacolombe {
      width: calc(89.7px * 0.5);

      @media (min-width: 600px) {
        width: calc(89.7px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 227px;
      }
    }

    &--cityfitness {
      width: calc(82.52px * 0.5);

      @media (min-width: 600px) {
        width: calc(82.52px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 165px;
      }
    }

    &--starbucks {
      width: calc(44.96px * 0.5);

      @media (min-width: 600px) {
        width: calc(44.96px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 90px;
      }
    }

    &--ritzcarlton {
      width: calc(53.95px * 0.5);

      @media (min-width: 600px) {
        width: calc(53.95px * 0.75);
      }

      @media (min-width: $largebp) {
        width: 108px;
      }
    }
  }
}
