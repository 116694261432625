@keyframes slideAndFade {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: translateY(5px);
  }
  100% {
    opacity: 0;
    transform: translateY(5px);
  }
}

.top {
  position: relative;
  color: $white;

  // svg {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   max-width: 915px;
  //   width: 80%;
  // }

  .video-wrap {
  }

  video {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    @media (min-width: $largebp) {
      height: 100vh;
    }

    object-fit: cover;
  }

  .top__button {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    @media (min-width: $largebp) {
      display: block;
    }
  }

  .top__scroll-down {
    position: absolute;
    bottom: 70px;
    left: 40px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    display: none;

    @media (min-width: $largebp) {
      display: block;
    }

    svg {
      margin: 15px auto 0;
      animation: slideAndFade 2s ease-in-out infinite;
    }
  }
}
