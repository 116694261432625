.infra {
  background: $lightPurpleBg;
  padding: 100px 0;

  @media (min-width: $largebp) {
    padding: 200px 80px 200px 0;
  }

  .width {
    position: relative;
  }

  &__title {
    font-size: 31px;
    margin-bottom: 20px;
    max-width: 400px;
    text-transform: none;

    @media (min-width: $largebp) {
      margin-bottom: 120px;
      font-size: 48px;
      max-width: 560px;
    }
  }

  &__image {
    width: 400px;
    margin-bottom: 30px;

    @media (min-width: $largebp) {
      margin-bottom: 0;
      right: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      width: calc(100% - 500px);
      max-width: none;
      max-width: 870px;
    }
  }

  .legend {
    @media (min-width: $largebp) {
      max-width: 500px;
    }

    &__color {
      &--one {
        background: #5d5cf1;
      }

      &--two {
        background: #a3a6c4;
      }

      &--three {
        background: #5695f7;
      }

      &--four {
        background: #7cdaf3;
      }

      &--five {
        background: #878af7;
      }

      &--six {
        background: #996cbe;
      }

      &--seven {
        background: #828282;
      }
    }
  }
}

.legend {
  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    text-transform: uppercase;

    @media (min-width: $largebp) {
      font-size: 20px;
      gap: 20px;
    }

    span:last-of-type {
      flex: 1;
    }
  }

  &__item + &__item {
    margin-top: 20px;
  }

  &__color {
    width: 18px;
    height: 18px;
    background: $purple;
    display: block;

    @media (min-width: $largebp) {
      width: 69px;
      height: 26px;
    }
  }
}
