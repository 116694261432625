.video-wrap {
  padding-bottom: 56.25%;
  position: relative;

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    height: auto;
  }
}

.width {
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: 1600px;

  @media (min-width: $largebp) {
    width: calc(100% - 80px);
  }

  &--mobile-full {
    width: 100%;

    @media (min-width: $largebp) {
      width: calc(100% - 80px);
    }
  }
}

.desktop {
  display: none;

  @media (min-width: $largebp) {
    display: block;
  }
}

.mobile {
  @media (min-width: $largebp) {
    display: none;
  }
}

.overflow-wrap {
  max-width: 100vw;
  overflow: hidden;
}

.sticky-section {
  position: sticky;
  top: 0;
}

.color-fade-section {
  &__color {
    background: $purple;
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0;
    margin-bottom: -100vh;
  }

  &__top {
    position: relative;
    z-index: 2;
  }
}
