$largebp: 1020px;
$xlargebp: 1320px;

$font: "Bio Sans", sans-serif;

$white: #ffffff;
$purple: #392e87;
$darkPurple: #332f47;
$lightPurpleBg: #dadaed;
$xlightPurpleBg: #ededf6;

$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;
