.features {
  background: $purple;
  color: $white;
  padding: 100px 0;

  @media (min-width: $largebp) {
    padding: 200px 80px 200px 0;
  }

  &__flex {
    @media (min-width: $largebp) {
      display: flex;
      gap: 80px;
    }

    @media (min-width: $xlargebp) {
      gap: 180px;
    }
  }

  &__header {
    @media (min-width: $largebp) {
      width: 40%;
      max-width: 440px;
    }
  }

  &__title {
    font-size: 31px;

    @media (min-width: $largebp) {
      font-size: 48px;
    }
  }

  &__divider {
    background-color: $white;
    height: 2px;
    width: 24px;
    margin: 20px 0 12px;
  }

  &__subtitle {
    font-size: 22px;
    max-width: 210px;

    @media (min-width: $largebp) {
      font-size: 35px;
      max-width: 575px;
    }
  }

  &__main {
    margin-top: 100px;

    @media (min-width: $largebp) {
      flex: 1;
      margin: 0;
    }
  }

  &__h4 {
    text-transform: none;
    font-size: 31px;
    margin-bottom: 24px;

    @media (min-width: $largebp) {
      font-size: 48px;
      margin-bottom: 48px;
    }
  }

  &__features {
    max-width: 860px;
  }

  &__feature {
    border-bottom: 1px solid $white;
    padding: 8px 0;

    h5 {
      font-size: 19px;

      @media (min-width: $largebp) {
        font-size: 25px;
      }
    }
  }
}
