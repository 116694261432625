.breakthrough {
  padding: 130px 0;
  // background: $purple;
  // background: linear-gradient($purple, $lightPurpleBg);
  color: $white;
  text-align: center;

  &__logo {
    svg {
      margin: 0 auto;
      width: 227px;

      @media (min-width: $largebp) {
        width: 289px;
      }
    }
  }

  h2 {
    margin: 60px 0;
    font-size: 31px;

    @media (min-width: $largebp) {
      font-size: 48px;
    }
  }
}
