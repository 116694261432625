.water {
  background: $purple;
  color: $white;
  position: relative;
  overflow: hidden;
  padding: 0 0 60px;

  @media (min-width: $largebp) {
    padding-top: 60px;
    padding-right: 80px;
  }

  &__flex {
    @media (min-width: $largebp) {
      display: flex;
      align-items: center;
      gap: 60px;
    }

    @media (min-width: $xlargebp) {
      gap: 80px;
    }
  }

  &__image {
    margin: 0 -20px;

    @media (min-width: $largebp) {
      margin: 0;
      order: 2;
      flex: 1;
    }

    img {
      max-width: none;
      width: 100%;

      @media (min-width: $largebp) {
        border-radius: 50%;
      }
    }
  }

  &__text {
    padding-top: 60px;

    @media (min-width: $largebp) {
      width: 40%;
      padding: 0;
      max-width: 600px;
    }
  }

  &__title {
    font-size: 31px;
    max-width: 450px;
    margin: 0 auto 30px;
    text-transform: none;

    @media (min-width: $largebp) {
      border-radius: 50%;
      font-size: 48px;
      margin: 0 0 60px;
    }
  }

  &__p {
    font-size: 19px;
    max-width: 450px;
    margin: 0 auto;
    text-transform: none;

    @media (min-width: $largebp) {
      border-radius: 50%;
      margin: 0;
      font-size: 25px;
    }
  }
}
