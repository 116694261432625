@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-ExtraBold.woff2") format("woff2"),
    url("./fonts/subset-BioSans-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-BoldItalic.woff2") format("woff2"),
    url("./fonts/subset-BioSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-Bold.woff2") format("woff2"),
    url("./fonts/subset-BioSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-ExtraLight.woff2") format("woff2"),
    url("./fonts/subset-BioSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-ExtraBoldItalic.woff2") format("woff2"),
    url("./fonts/subset-BioSans-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-ExtraLightItalic.woff2") format("woff2"),
    url("./fonts/subset-BioSans-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-Italic.woff2") format("woff2"),
    url("./fonts/subset-BioSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-Regular.woff2") format("woff2"),
    url("./fonts/subset-BioSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-Light.woff2") format("woff2"),
    url("./fonts/subset-BioSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-SemiBold.woff2") format("woff2"),
    url("./fonts/subset-BioSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-LightItalic.woff2") format("woff2"),
    url("./fonts/subset-BioSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bio Sans";
  src: url("./fonts/subset-BioSans-SemiBoldItalic.woff2") format("woff2"),
    url("./fonts/subset-BioSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
