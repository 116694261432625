.footer {
  background: $purple;
  color: $white;
  padding-bottom: 100px;
  position: relative;
  z-index: 10;

  @media (min-width: $largebp) {
    padding: 130px 80px 130px 0;
  }

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }

  &__mobile-logo {
    padding: 100px 0;

    @media (min-width: $largebp) {
      display: none;
    }

    svg {
      width: 262px;
      margin: 0 auto;
    }
  }

  &__flex {
    @media (min-width: $largebp) {
      display: flex;
      gap: 120px;
    }

    @media (min-width: $xlargebp) {
      gap: 10%;
    }
  }

  &__touch {
    padding: 40px 0;

    @media (min-width: $largebp) {
      order: 3;
      padding-bottom: 0;
    }

    @media (min-width: $xlargebp) {
      width: auto;
      flex: 1;
    }

    h2 {
      text-transform: none;
      font-size: 22px;
      margin-bottom: 40px;

      @media (min-width: $largebp) {
        font-size: 35px;
        margin-bottom: 100px;
      }
    }
  }

  &__right-flex {
    position: relative;
    padding-left: 62px;

    @media (min-width: $xlargebp) {
      padding: 0;
      display: flex;
      gap: 40px;
    }
  }

  &__right-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;

    @media (min-width: $xlargebp) {
      position: static;
      width: 64px;
    }
  }

  &__right-contact {
    text-transform: none;
    font-weight: 300;
    line-height: 1.4;

    ul {
      font-size: 15px;

      @media (min-width: $largebp) {
        font-size: 17px;
      }
    }

    & + & {
      margin-top: 24px;

      @media (min-width: $xlargebp) {
        margin: 0;
      }
    }

    h3 {
      font-size: 19px;
      text-transform: none;
      margin-bottom: 5px;

      @media (min-width: $largebp) {
        font-size: 25px;
      }
    }

    h4 {
      font-size: 15px;
      font-weight: 300;
      text-transform: none;

      @media (min-width: $largebp) {
        font-size: 17px;
      }
    }
  }

  &__logo {
    display: none;

    @media (min-width: $largebp) {
      display: block;
      max-width: 440px;
      padding-bottom: 60px;
    }
  }

  &__divider {
    height: 1px;
    background: $white;
    width: 100%;

    &--mobile {
      @media (min-width: $largebp) {
        display: none;
      }
    }
  }

  &__copy {
    @media (min-width: $largebp) {
      flex: 1;
    }
  }

  &__copy-flex {
    display: flex;
    flex-direction: column;
    padding-top: 40px;

    @media (min-width: $largebp) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__copyright {
    font-size: 19px;
    order: 2;
    margin-top: 54px;
    margin-left: 20px;

    @media (min-width: $largebp) {
      order: -1;
      margin: 0;
      font-size: 25px;
    }

    small {
      font-size: 15px;
      display: block;
      font-weight: 300;
      margin-top: 4px;

      @media (min-width: $largebp) {
        font-size: 17px;
      }
    }
  }

  &__links {
    li + li {
      margin-top: 12px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 300;

      @media (min-width: $largebp) {
        font-size: 17px;
      }
    }
  }
}
