.header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  color: $white;
  background-color: $purple;
  display: none;

  @media (min-width: $largebp) {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding-top: 26px;
    padding-right: 23px;
    width: 80px;
    height: 100vh;
    border-left: 1px solid $white;
    transition: 0.2s width, 0.2s background-color;
  }

  &:hover {
    width: 260px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  svg {
    width: 40px;
    margin-left: auto;
    color: $white;
  }

  &:hover svg {
    color: $purple;
  }

  a {
    color: $white;
    transition: 0.2s color;
  }

  &:hover a {
    color: $purple;
  }

  &__flex {
    flex: 1;
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      flex-direction: column;
      font-weight: 300;
      flex: 1;
    }

    li {
      border-left: 1px solid $white;
      padding-left: 20px;
      flex: 1;
      transition: 0.2s border-color;
      margin-left: -1px;
      display: flex;
      align-items: center;

      &:hover {
        border-color: $purple;
      }

      .header:hover &.header__link--active,
      .header:hover &.header__link--always-active {
        border-color: $purple;
      }
    }

    a {
      display: block;
    }

    span,
    strong {
      display: block;
    }

    span {
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 26px;
      border-radius: 50%;
    }

    strong {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 5px;
      max-height: 0;
      width: 0;
      overflow: hidden;
      transition: 0.2s max-height;
    }

    .header:hover & strong {
      width: auto;
      max-height: 30px;
    }

    .header__flex li:nth-of-type(1) strong {
      transition-delay: 0.3s;
    }

    .header__flex li:nth-of-type(2) strong {
      transition-delay: 0.4s;
    }

    .header__flex li:nth-of-type(3) strong {
      transition-delay: 0.5s;
    }

    .header__flex li:nth-of-type(4) strong {
      transition-delay: 0.6s;
    }

    .header__flex li:nth-of-type(5) strong {
      transition-delay: 0.7s;
    }
    .header__flex li:nth-of-type(6) strong {
      transition-delay: 0.8s;
    }

    .header__flex li:nth-of-type(7) strong {
      transition-delay: 0.9s;
    }

    .header__flex li:nth-of-type(8) strong {
      transition-delay: 1s;
    }
  }
}

.header__flex ul {
  li:nth-of-type(1) strong {
    transition-delay: 0.2s;
  }

  li:nth-of-type(2) strong {
    transition-delay: 0.25s;
  }

  li:nth-of-type(3) strong {
    transition-delay: 0.3s;
  }

  li:nth-of-type(4) strong {
    transition-delay: 0.35s;
  }

  li:nth-of-type(5) strong {
    transition-delay: 0.4s;
  }

  li:nth-of-type(6) strong {
    transition-delay: 0.45s;
  }

  li:nth-of-type(7) strong {
    transition-delay: 0.5s;
  }
}

.header__link--last span {
  background: $white;
  color: $purple;
}

.header:hover .header__link--last span,
.header:hover .header__flex li:hover span {
  color: $white;
  background-color: $purple;
}
