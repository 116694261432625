@media (min-width: $largebp) {
  .fade-in-up,
  .fade-in-down,
  .fade-in-left,
  .fade-in-right,
  .slide-in-left-full,
  .slide-in-right-full,
  .fade-in,
  .scale-in-small {
    transition: 1s transform, 1s opacity;

    &.active,
    .ready &.on-ready {
      opacity: 1;
      transform: none;
    }
  }

  .fade-in-up {
    opacity: 0;
    transform: translateY(40px);
  }

  .fade-in-down {
    opacity: 0;
    transform: translateY(-40px);
  }

  .fade-in-left {
    opacity: 0;
    transform: translateX(-40px);
  }

  .fade-in-right {
    opacity: 0;
    transform: translateX(40px);
  }

  .fade-in {
    opacity: 0;
  }

  .scale-in-small {
    opacity: 0;
    transform: scale(0.6);
  }

  .slide-in-left-full {
    transform: translateX(-100%);
  }

  .slide-in-right-full {
    transform: translateX(100%);
  }

  .delay2 {
    transition-delay: 0.2s;
  }

  .delay3 {
    transition-delay: 0.3s;
  }

  .delay4 {
    transition-delay: 0.4s;
  }

  .delay5 {
    transition-delay: 0.5s;
  }

  .delay6 {
    transition-delay: 0.6s;
  }

  .delay7 {
    transition-delay: 0.7s;
  }

  .delay8 {
    transition-delay: 0.8s;
  }

  .delay9 {
    transition-delay: 0.9s;
  }

  .delay10 {
    transition-delay: 1s;
  }

  .delay11 {
    transition-delay: 1.1s;
  }

  .delay12 {
    transition-delay: 1.2s;
  }
}
