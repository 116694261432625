.epicenter {
  // background: $purple;
  color: $white;
  padding-bottom: 80px;

  @media (min-width: $largebp) {
    padding-right: 80px;
  }

  .width {
    @media (min-width: $largebp) {
      display: flex;
      flex-direction: column;
    }
  }

  &__image {
    margin: 0 -20px;

    @media (min-width: $largebp) {
      order: 2;
      margin: 80px 0 0 0;
    }

    img {
      @media (min-width: $largebp) {
        width: 80%;
        max-width: 970px;
        margin-left: auto;
      }
    }
  }

  &__flex {
    margin-top: 50px;

    @media (min-width: $largebp) {
      margin: 0;
      display: flex;
      justify-content: space-between;
    }
  }

  &__heading {
    @media (min-width: $largebp) {
      max-width: 626px;
      flex: 1;
    }

    h2 {
      text-transform: none;
      font-size: 31px;

      @media (min-width: $largebp) {
        font-size: 48px;
      }
    }
  }

  &__desc {
    margin-top: 24px;

    @media (min-width: $largebp) {
      max-width: 450px;
      width: 50%;
      margin: 0;
    }

    p,
    h4 {
      font-size: 19px;
      text-transform: none;

      @media (min-width: $largebp) {
        font-size: 25px;
      }
    }
  }
}
